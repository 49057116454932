/* General notification container with full screen width */
.notification-container {
  padding: 15px;
  background-color: #2c2f38; /* Darker background for a modern look */
  min-height: 100vh; /* Ensure full viewport height */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center; /* Center align content */
  color: #f0f0f0; /* Light text color for better contrast */
  overflow: hidden; /* Prevent overflow */
  width: 100%; /* Ensure the container takes full width of the screen */
  max-width: 100%; /* Prevent it from exceeding the screen width */
  box-sizing: border-box;
}

/* Notification list with smooth scrolling and spacing */
.notification-list {
  display: flex;
  flex-direction: column;
  gap: 25px; /* Space between notifications */
  max-height: 80vh; /* Set max height for the scrollable list */
  overflow-y: auto;
  padding: 10px;
  width: 100%; /* Ensure the list takes full width */
  box-sizing: border-box;
}

/* Individual notification item with updated card style */
.notification-item {
  display: flex;
  flex-direction: column; /* Stack profile and buttons vertically */
  align-items: center; /* Center align all content */
  justify-content: flex-start;
  padding: 20px;
  background-color: #333c49; /* Darker background for item */
  border-radius: 12px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* Deeper shadow for depth */
  border: 1px solid #444; /* Dark border */
  width: 100%; /* Make sure it stretches the whole container */
  gap: 15px; /* Space between sections */
  cursor: pointer; /* Indicate clickable items */
  box-sizing: border-box;
}

/* Profile avatar on the left */
.notification-avatar {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  margin-right: 15px; /* Space between avatar and name */
  object-fit: cover;
  border: 3px solid #9c27b0; /* Purple border around avatar */
  transition: transform 0.3s ease-in-out;
}

/* Header section (photo and name aligned) */
.notification-header {
  display: flex;
  align-items: center; /* Align profile photo and name on the same line */
  justify-content: flex-start; /* Align items to the left */
  width: 100%;
  gap: 15px;
}

/* Notification details container (name part) */
.notification-details {
  display: flex;
  flex-direction: column;
  gap: 5px;
  justify-content: center;
  flex-grow: 1; /* Allow the name section to take available space */
}

/* Username style with better readability */
.notification-username {
  margin: 0;
  font-size: 22px;
  font-weight: bold;
  color: #9c27b0; /* Purple color for username */
  letter-spacing: 1px;
  text-transform: capitalize;
  word-wrap: break-word;
}

/* Name style with better spacing */
.notification-name {
  margin: 0;
  font-size: 16px;
  color: #bbb; /* Lighter gray for name */
  word-wrap: break-word;
  line-height: 1.4;
}

/* Buttons container - Align Accept and Reject buttons horizontally */
.notification-buttons {
  display: flex;
  justify-content: center; /* Center align buttons */
  gap: 15px; /* Space between the buttons */
  width: 100%;
  margin-top: 10px;
}

/* Button styles with smooth transitions */
.notification-button {
  padding: 12px 20px;
  border: none;
  border-radius: 30px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease, box-shadow 0.3s ease;
  font-size: 16px;
  width: 48%; /* Buttons take up 48% width for even distribution */
  text-transform: uppercase;
  letter-spacing: 0.5px;
  box-sizing: border-box;
}

/* Accept button style */
.notification-button.accept {
  background-color: #9c27b0; /* Purple background */
  color: white;
}

.notification-button.accept:hover {
  background-color: #7b1fa2; /* Darker purple on hover */
  transform: translateY(-2px); /* Subtle lift effect */
  box-shadow: 0 4px 12px rgba(156, 39, 176, 0.5); /* Soft purple shadow */
}

/* Reject button style */
.notification-button.reject {
  background-color: #e57373; /* Red background */
  color: white;
}

.notification-button.reject:hover {
  background-color: #f44336; /* Darker red on hover */
  transform: translateY(-2px); /* Subtle lift effect */
  box-shadow: 0 4px 12px rgba(244, 67, 54, 0.5); /* Soft red shadow */
}

/* Scrollbar styles for smoother appearance */
.notification-list::-webkit-scrollbar {
  width: 10px;
}

.notification-list::-webkit-scrollbar-thumb {
  background-color: #9c27b0; /* Purple thumb */
  border-radius: 10px;
}

.notification-list::-webkit-scrollbar-thumb:hover {
  background-color: #8e24aa; /* Slightly darker purple on hover */
}

.notification-list::-webkit-scrollbar-track {
  background-color: #2c2f38; /* Dark background for scrollbar track */
}

/* Media Query for mobile with optimized layout */
@media (max-width: 768px) {
  /* Notification Item adjustments */
  .notification-item {
    padding: 15px;
    border-radius: 12px;
    flex-direction: column; /* Stack elements vertically on smaller screens */
    align-items: flex-start;
  }

  .notification-username {
    font-size: 20px;
  }

  .notification-name {
    font-size: 14px;
  }

  .notification-avatar {
    width: 70px;
    height: 70px;
  }

  /* Button styles for smaller screens */
  .notification-button {
    font-size: 14px;
    padding: 10px 18px;
    width: 48%; /* Ensure buttons are evenly distributed */
  }
}
