@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap');

.search-page {
    font-family: 'Poppins', sans-serif;
    display: flex;
    flex-direction: column;
    padding: 0;
    margin: 0;
    height: 100vh;
    width: 100%;
    box-sizing: border-box;
}

h1 {
    color: #4f4f4f;
    font-size: 28px;
    font-weight: bold;
    margin-bottom: 20px;
    text-align: center;
    width: 100%;
    padding: 0 10px;
}

/* User List */
.search-user-list {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    gap: 15px;
    width: 100%;
    padding: 0 10px;
    overflow-y: auto;
    margin-bottom: 80px;
}

.search-user-list::-webkit-scrollbar {
    display: none;
}

/* User Item Styling */
.search-user-item {
    background-color: white;
    padding: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 15px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.3s ease;
    width: 100%;
}

.search-user-item:hover {
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

.search-profile-photo {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 15px;
    transition: transform 0.3s ease;
}

.search-profile-photo:hover {
    transform: scale(1.05);
}

.search-user-info {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}

.search-user-info h3 {
    font-size: 20px;
    margin: 0;
    color: #333;
    font-weight: bold;
}

.search-user-info p {
    font-size: 16px;
    margin-top: 5px;
    color: #777;
}

.search-connect-button {
    background-color: #6a4caf;
    color: white;
    border: none;
    border-radius: 8px;
    padding: 6px 12px; /* Smaller padding */
    font-size: 12px; /* Smaller font size */
    cursor: pointer;
    transition: background-color 0.3s ease;
    width: 100px;
}

.search-connect-button:hover {
    background-color: #5a3e9a;
}

.search-connect-button:focus {
    outline: none;
    box-shadow: 0 0 5px rgba(106, 76, 175, 0.8);
}

/* VIP Styling for Dj8907 */
.search-fixed-user {
    top: 0;
    z-index: 1;
    background-color: #fffcf0;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.15);
    border: 3px solid gold;
    padding: 15px;
    width: 100%;
}

.search-fixed-user .search-profile-photo {
    border: 5px solid gold;
    box-shadow: 0 0 15px rgba(255, 215, 0, 0.5);
}

.search-fixed-user .search-user-info h3 {
    color: #6a4caf;
    font-size: 22px;
    font-weight: bold;
}

.search-fixed-user .search-connect-button {
    background-color: #ffd700;
    font-size: 14px;
}

.search-fixed-user .search-connect-button:hover {
    background-color: #f4c200;
}

/* Mobile Adjustments */
@media (max-width: 768px) {
    h1 {
        font-size: 24px;
    }

    .search-user-list {
        gap: 12px;
        padding: 0 5px;
    }

    .search-user-item {
        padding: 12px;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;
    }

    .search-profile-photo {
        width: 60px;
        height: 60px;
    }

    .search-user-info h3 {
        font-size: 18px;
    }

    .search-user-info p {
        font-size: 14px;
    }

    .search-connect-button {
        padding: 6px 10px; /* Even smaller padding */
        font-size: 10px; /* Further reduced font size */
        width: 90px; /* Slightly smaller button width */
    }
}

/* Very small screen (mobile portrait) adjustments */
@media (max-width: 480px) {
    .search-user-item {
        padding: 10px;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
    }

    .search-profile-photo {
        width: 50px;
        height: 50px;
    }

    .search-user-info h3 {
        font-size: 16px;
    }

    .search-user-info p {
        font-size: 12px;
    }

    .search-connect-button {
        padding: 6px 10px;
        font-size: 10px;
        width: 80px; /* Smaller button width */
    }
}

/* Premium Badge Styling */
.search-premium-badge {
  font-style: italic;
  font-size: 12px;
  color: #ffd700;
  margin-left: 10px;
  background: #fff8e1;
  padding: 2px 6px;
  border-radius: 5px;
  border: 1px solid #ffd700;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.search-bar {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
  position: sticky;
  width: 100%;
}

.search-input {
  padding: 6px;
  font-size: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
}

.search-button {
  margin-left: 6px;
  padding: 6px 12px;
  font-size: 12px;
  background-color: #6a4caf;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  width: 100%;
}

.search-button:hover {
  background-color: #5a3e9a;
}
