/* Dark background with semi-transparent overlay */
.premiumnotificationmodal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8); /* Darker overlay */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  /* Dark modal content with white and purple accents */
  .premiumnotificationmodal-content {
    background: #2c2f38; /* Dark background */
    padding: 30px;
    border-radius: 8px;
    width: 320px;
    text-align: center;
    color: white; /* White text */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3); /* Soft shadow */
  }
  
  /* Title style */
  .premiumnotificationmodal-title {
    font-size: 1.5rem;
    color: #9c27b0; /* Purple color for the title */
    margin-bottom: 15px;
  }
  
  /* Description text */
  .premiumnotificationmodal-description {
    font-size: 1rem;
    color: #ccc; /* Lighter gray for the text */
    margin-bottom: 20px;
  }
  
  /* Actions buttons */
  .premiumnotificationmodal-actions {
    display: flex;
    justify-content: space-evenly;
    width: 100%;
  }
  
  .premiumnotificationmodal-btn-premium {
    padding: 12px 20px;
    background-color: #9c27b0; /* Purple background */
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.3s ease, transform 0.3s ease;
  }
  
  .premiumnotificationmodal-btn-premium:hover {
    background-color: #7b1fa2; /* Slightly darker purple for hover effect */
    transform: scale(1.05); /* Slight scaling effect on hover */
  }
  
  .premiumnotificationmodal-btn-premium:focus {
    outline: none;
  }
  
  .premiumnotificationmodal-btn-close {
    padding: 12px 20px;
    background-color: #444; /* Dark gray for "Close" button */
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
  }
  
  .premiumnotificationmodal-btn-close:hover {
    background-color: #666; /* Lighter gray when hovered */
  }
  
  .premiumnotificationmodal-btn-close:focus {
    outline: none;
  }
  