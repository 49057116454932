/* Main contactus container */
.contactus-container {
  background-color: #ffffff;
  width: 100%;
  max-width: 1000px;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow-y: auto; /* Enable scrolling for container when content overflows */
  height: 100vh; /* Ensure container takes up full screen height */
}

/* Heading */
.contactus-container h2 {
  font-size: 1.8rem;
  color: #333;
  text-align: center;
  margin-bottom: 20px;
}

/* Contact form styling */
.contactus-form {
  margin-top: 20px;
}

.contactus-form h3 {
  font-size: 1.3rem;
  color: #007BFF;
  margin-bottom: 10px;
}

/* Form fields */
.contactus-form-field {
  margin-bottom: 15px;
}

.contactus-form-field label {
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
  color: #333;
}

.contactus-form-field input,
.contactus-form-field textarea {
  width: 100%;
  padding: 12px;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-sizing: border-box; /* Ensures padding doesn't affect width */
  margin-bottom: 10px;
}

.contactus-form-field input:focus,
.contactus-form-field textarea:focus {
  border-color: #007BFF;
  outline: none;
}

.contactus-form button {
  width: 100%;
  padding: 12px 20px;
  background-color: #007BFF;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
}

.contactus-form button:hover {
  background-color: #0056b3;
}

/* Contact history section */
.contactus-history {
  margin-top: 20px;
}

.contactus-history h3 {
  font-size: 1.3rem;
  color: #007BFF;
  margin-bottom: 10px;
}

.contactus-history ul {
  list-style: none;
  padding: 0;
}

.contactus-history-item {
  margin-bottom: 20px;
  padding: 15px;
  background-color: #f9f9f9;
  border-radius: 8px;
  border: 1px solid #ddd;
  box-sizing: border-box;
}

.contactus-history-item:hover {
  background-color: #f1f1f1;
}

.contactus-contact-info {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.contactus-contact-info strong {
  color: #333;
}

.contactus-contact-info span {
  color: #555;
  font-size: 0.9rem;
}

.contactus-history-item p {
  color: #333;
  line-height: 1.5;
}

/* Media Query for larger screens (tablets and up) */
@media (min-width: 768px) {
  .contactus-container {
    padding: 30px;
  }

  .contactus-form button {
    padding: 12px 25px;
  }

  .contactus-history-item {
    padding: 20px;
  }
}

/* Media Query for smaller screen sizes (mobile-first) */
@media (max-width: 1024px) {
  .contactus-container {
    padding: 20px;
  }

  .contactus-form button {
    font-size: 1.2rem;
    padding: 12px 18px;
  }

  .contactus-history-item {
    padding: 15px;
  }
}

@media (max-width: 768px) {
  .contactus-container {
    padding: 15px;
  }

  .contactus-form h3 {
    font-size: 1.5rem;
  }

  .contactus-history h3 {
    font-size: 1.5rem;
  }

  .contactus-form button {
    font-size: 1.5rem;
    padding: 14px 18px;
  }

  .contactus-history-item {
    padding: 15px;
  }
}

@media (max-width: 480px) {
  .contactus-container {
    padding: 10px;
  }

  .contactus-form h3 {
    font-size: 1.3rem;
  }

  .contactus-history h3 {
    font-size: 1.3rem;
  }

  .contactus-form button {
    font-size: 1.4rem;
    padding: 14px 18px;
  }

  .contactus-history-item {
    padding: 12px;
  }
}
