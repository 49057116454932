/* footer.style.css */

.footer-container {
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: #333;
    padding: 10px 0;
    box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.2);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
    border-radius: 10px 10px 0 0;
}

.footer-icons {
    display: flex;
    justify-content: space-around;
    width: 90%;
    max-width: 900px;
}

.footer-icon {
    text-align: center;
    color: #fff;
    font-size: 18px;
    position: relative;
    transition: transform 0.3s ease-in-out, color 0.3s ease;
}

.footer-icon:hover {
    transform: translateY(-5px);  /* Adds hover animation */
    color: #f39c12;  /* Changes color on hover */
}

.footer-icon i {
    font-size: 24px;
    margin-bottom: 5px;
    transition: color 0.3s ease;
}

.footer-label {
    font-size: 12px;
    display: block;
    color: #e7e7e7;
    transition: color 0.3s ease;
}

.footer-icon:hover .footer-label {
    color: #f39c12;  /* Change label color on hover */
}

/* Add active color for the current page */
.footer-icon.active {
    color: #f39c12;
}

.footer-icon.active i {
    color: #f39c12;
}
