/* Ensure body and html take full height */
html, body {
  height: 100%;
  margin: 0;
  padding: 0;
}

/* Container for the profile page */
.edit-profile-container {
  display: flex;
  flex-direction: column;
  height: 100vh;  /* Full viewport height */
  padding: 20px;
  background-color: #ffffff; /* Soft white with a hint of purple */
  border-radius: 15px;
  box-shadow: 0 8px 15px rgba(255, 255, 255, 0.1); /* Light shadow */
  overflow: hidden;
  position: relative;
  font-family: 'Poppins', sans-serif;
}

/* Scrollable Profile Form Section */
.profile-form {
  flex-grow: 1;  /* Allow the form to take up remaining space */
  overflow-y: auto;  /* Enable scrolling when the content overflows */
  padding-right: 15px;
  scrollbar-width: thin;  /* Firefox */
  scrollbar-color: #6A0dad #f8f8fc;  /* Lavender Purple thumb and white track for Firefox */
}

/* Custom scrollbar for Webkit browsers (Chrome, Safari, Edge) */
.profile-form::-webkit-scrollbar {
  width: 6px;  /* Set width of the scrollbar */
}

.profile-form::-webkit-scrollbar-thumb {
  background-color: #6A0dad;  /* Lavender Purple thumb color */
  border-radius: 10px;
  transition: background-color 0.3s ease;
}

.profile-form::-webkit-scrollbar-thumb:hover {
  background-color: #4E1070;  /* Darker Purple on hover */
}

.profile-form::-webkit-scrollbar-track {
  background-color: #f8f8fc;  /* White track */
  border-radius: 10px;
  transition: background-color 0.3s ease;
}

.profile-form::-webkit-scrollbar-track:hover {
  background-color: #e5e5f3;  /* Light Purple on hover */
}

/* Input Labels and Inputs */
.input-label {
  color: #6A0dad;  /* Lavender Purple */
  font-size: 1.2rem;
  margin-bottom: 8px;
  display: block;
}

.input-field {
  width: 100%;
  padding: 12px;
  margin-bottom: 20px;
  border: 2px solid #b299d4;  /* Light Purple border */
  background-color: #fff;  /* Pure white background */
  color: #4E1070;  /* Dark Purple text */
  font-size: 1rem;
  border-radius: 10px;
  transition: border-color 0.3s ease;
}

.input-field:focus {
  border-color: #6A0dad;  /* Lavender Purple focus */
  outline: none;
  background-color: #f1eefb;  /* Very Light Purple */
}

/* Profile Picture Section */
.profile-picture-section {
  display: flex;
  flex-direction: column; /* Align children vertically */
  justify-content: center; /* Vertically center the content */
  align-items: center; /* Horizontally center the content */
  margin-bottom: 30px;
}

/* Profile Picture */
.profile-picture {
  display: inline-block;
  position: relative;
}

.profile-picture-img {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  object-fit: cover;
  border: 5px solid #6A0dad; /* Lavender Purple border */
}

/* Edit Picture Button */
.edit-picture-button {
  display: inline-block;
  padding: 12px 24px;
  background-color: #6A0dad;  /* Lavender Purple */
  color: white;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  font-size: 1.1rem;
  margin-top: 20px;
  text-transform: uppercase;
  letter-spacing: 1px;
  box-shadow: 0 5px 15px rgba(106, 13, 173, 0.3);
  transition: background-color 0.3s ease, transform 0.3s ease;
}

/* Hover Effect */
.edit-picture-button:hover {
  background-color: #4E1070;  /* Darker Purple */
  transform: translateY(-3px);
}

/* Active Button Effect */
.edit-picture-button:active {
  transform: translateY(2px);
}

/* Save Changes Button */
.save-changes-button {
  display: block;
  width: 100%;
  padding: 12px 0;
  background-color: #6A0dad;  /* Lavender Purple */
  color: white;
  font-size: 1.2rem;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  text-transform: uppercase;
  letter-spacing: 1px;
  transition: background-color 0.3s ease, transform 0.3s ease;
  box-shadow: 0 5px 15px rgba(106, 13, 173, 0.3);
}

.save-changes-button:hover {
  background-color: #4E1070;  /* Darker Purple */
  transform: translateY(-3px);
}

.save-changes-button:active {
  transform: translateY(2px);
}

/* Keyframes for animations */
@keyframes fadeIn {
  0% {
      opacity: 0;
  }
  100% {
      opacity: 1;
  }
}

.pro-error-message {
  font-size: 1.4vw;
  color: red;
  margin-top: 5px;
}
