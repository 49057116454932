/* header.style.css */

/* Header container styling */
.dashboardheader-header {
    position: fixed;
    top: 0;
    width: 100%;
    background-color: #333;  /* Dark background */
    color: #fff;
    padding: 10px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 100;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

/* Company name styling */
.dashboardheader-company-name {
    font-size: 24px;
    font-weight: bold;
    color: #f39c12; /* A golden color for the company name */
    letter-spacing: 1px;
}

/* Message icon container styling */
.dashboardheader-message-icon {
    font-size: 24px;
    cursor: pointer;
    transition: transform 0.3s ease, color 0.3s ease;
}

/* Hover effect for message icon */
.dashboardheader-message-icon:hover {
    transform: scale(1.1); /* Slight zoom effect */
    color: #f39c12; /* Change color on hover */
}
