/* pro-yourprofile.style.css */

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap');

/* Global Body Styling */
body {
  font-family: 'Poppins', sans-serif;
  margin: 0;
  padding: 0;
  background-color: #F5F5F5;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  overflow-x: hidden;
}

/* Profile Popup */
.pro-profile-popup-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
}

.pro-profile-popup {
  background: #FFFFFF;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  width: 80%;
  max-width: 400px;
}

.pro-profile-popup h2 {
  font-size: 2rem;
  color: #6A2C8B; /* Purple color */
}

.pro-profile-popup p {
  color: #555;
  font-size: 1.1rem;
}

.pro-edit-profile-button {
  background-color: #6A2C8B;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 25px;
  font-size: 1.2rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.pro-edit-profile-button:hover {
  background-color: #9B6BBF;
}

/* Profile Content Layout */
.pro-profile-content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 20px;
  background-color: white;
  height: 100%;
  overflow-y: auto; /* Enable vertical scrolling */
  scrollbar-width: thin;
  scrollbar-color: #6A2C8B #F5F5F5; /* Custom scrollbar for Firefox */
}

.pro-profile-content::-webkit-scrollbar {
  width: 0px;
}

.pro-profile-content::-webkit-scrollbar-thumb {
  background-color: #6A2C8B;
  border-radius: 0px;
}

.pro-profile-content::-webkit-scrollbar-track {
  background-color: #F5F5F5;
}

/* Prevent content overflow */
.pro-profile-details,
.pro-profile-info,
.pro-profile-more-details,
.pro-profile-actions,
.pro-media-tabs,
.pro-media-content {
  width: 100%;
  max-width: 900px;
  margin-bottom: 20px;
}

/* Profile Details */
.pro-profile-details {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}

.pro-profile-photo-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.pro-profile-photo-container img {
  width: 150px; /* Keep the profile image size constant */
  height: 150px;
  border-radius: 50%;
  object-fit: cover;
  cursor: pointer;
}

.pro-profile-stats {
  display: flex;
  justify-content: center;
  gap: 30px;
}

.pro-stat-item {
  text-align: center;
}

.pro-stat-number {
  font-size: 1.4rem;
  color: #6A2C8B; /* Purple color */
}

.pro-stat-label {
  font-size: 1rem;
  color: #777;
}

/* Profile Info */
.pro-profile-info {
  text-align: center;
  margin-bottom: 20px;
}

.pro-profile-name {
  font-size: 1.8rem;
  color: #333;
}

.pro-profile-age-profession {
  font-size: 1.2rem;
  color: #555;
  margin: 10px 0;
}

.pro-profile-bio {
  font-size: 1.1rem;
  color: #777;
  margin: 10px 0;
}

/* Profile More Details */
.pro-profile-more-details {
  text-align: center;
  font-size: 1rem;
  color: #555;
  margin-bottom: 20px;
}

/* Profile Action Buttons */
.pro-profile-actions {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  margin-bottom: 20px;
}

.pro-profile-button {
  padding: 10px 20px;
  border: none;
  background-color: #6A2C8B;
  color: white;
  font-size: 1.1rem;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.pro-profile-button:hover {
  background-color: #9B6BBF;
}

/* Media Tabs (Photos & Videos) */
.pro-media-tabs {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 20px;
}

.pro-tab-button {
  background-color: #FFFFFF;
  color: #6A2C8B;
  padding: 10px 20px;
  border: 1px solid #6A2C8B;
  border-radius: 8px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.pro-tab-button.active {
  background-color: #6A2C8B;
  color: white;
}

.pro-tab-button:hover {
  background-color: #9B6BBF;
  color: white;
}

/* Media Content */
.pro-media-content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  padding: 20px;
  font-size: 1.1rem;
  color: #555;
}

.pro-media-content p {
  text-align: center;
}

/* Modal for Viewing Photo */
.pro-photo-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.pro-photo-modal-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  position: relative;
}

.pro-photo-modal-image {
  max-width: 100%;
  max-height: 80vh;
  object-fit: contain;
}

.pro-close-photo-modal {
  position: absolute;
  top: 10px;
  right: 10px;
  background: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
  border-radius: 5px;
  font-size: 1.2rem;
}

/* Responsive styles for smaller screens */
@media (max-width: 768px) {
  .pro-profile-content {
    padding: 10px;
    height: auto; /* Allow content to take its natural height */
  }

  .pro-profile-photo-container img {
    width: 120px;
    height: 120px;
  }

  .pro-profile-name {
    font-size: 1.6rem;
  }

  .pro-profile-age-profession,
  .pro-profile-bio {
    font-size: 1rem;
  }

  .pro-media-tabs {
    flex-direction: column;
    align-items: center;
  }

  .pro-tab-button {
    width: 100%;
    margin: 5px 0;
  }

  /* Ensure the profile content has a scrollbar on small screens */
  .pro-profile-content {
    overflow-y: scroll; /* Ensure scroll is enabled */
    max-height: 100vh; /* Limit the height of the profile content */
  }
}

@media (max-width: 480px) {
  .pro-profile-photo-container img {
    width: 100px;
    height: 100px;
  }

  .pro-profile-name {
    font-size: 1.4rem;
  }

  .pro-profile-age-profession {
    font-size: 0.9rem;
  }

  .pro-profile-bio {
    font-size: 1rem;
  }

  .pro-media-tabs {
    flex-direction: column;
    align-items: center;
  }

  .pro-tab-button {
    width: 100%;
    margin: 5px 0;
    font-size: 1rem;
  }

  /* Ensure the profile content has a scrollbar on small screens */
  .pro-profile-content {
    overflow-y: scroll; /* Ensure scroll is enabled */
    max-height: 100vh; /* Limit the height of the profile content */
  }
}

.pro-stat-link {
  text-decoration: none; /* Remove underline */
  color: inherit; /* Inherit the color from parent */
  cursor: pointer; /* Change cursor to pointer to indicate it's clickable */
}

.pro-stat-link:hover {
  opacity: 0.8; /* Optional: Add hover effect */
}
