/* Dark background with semi-transparent overlay */
.friendrequestsuccess-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8); /* Darker overlay */
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background 0.3s ease;
}

/* Dark modal content with a touch of gradient and box shadow for depth */
.friendrequestsuccess-content {
  background: linear-gradient(145deg, #2c2f38, #3a4048); /* Subtle gradient */
  padding: 40px 30px;
  border-radius: 12px;
  width: 340px;
  text-align: center;
  color: #fff; /* White text */
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3); /* Deep box shadow */
  transform: scale(0.95);
  animation: fadeIn 0.4s ease-out forwards;
}

/* Title style with modern font and larger size */
.friendrequestsuccess-title {
  font-size: 1.8rem;
  color: #4caf50; /* Green color for success */
  margin-bottom: 15px;
  font-weight: 600;
  letter-spacing: 1px;
  text-transform: uppercase;
  text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.2);
}

/* Description text with lighter font and gentle color */
.friendrequestsuccess-description {
  font-size: 1.1rem;
  color: #ddd; /* Lighter gray for the text */
  margin-bottom: 20px;
  line-height: 1.5;
}

/* Actions buttons container */
.friendrequestsuccess-actions {
  display: flex;
  justify-content: space-around;
  width: 100%;
  margin-top: 20px;
}

/* Close button with smooth hover transition */
.friendrequestsuccess-btn-close {
  padding: 15px 25px;
  background-color: #4caf50; /* Green background for success */
  color: white;
  border: none;
  border-radius: 30px;
  cursor: pointer;
  font-size: 1.2rem;
  transition: background-color 0.3s ease, transform 0.3s ease, box-shadow 0.3s ease;
  width: 45%;
}

.friendrequestsuccess-btn-close:hover {
  background-color: #388e3c; /* Darker green for hover effect */
  transform: translateY(-4px); /* Lift effect */
  box-shadow: 0 4px 12px rgba(56, 142, 60, 0.5); /* Subtle shadow on hover */
}

.friendrequestsuccess-btn-close:focus {
  outline: none;
}

/* Animation for fading in the modal */
@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: scale(0.8);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

/* Additional responsiveness for mobile screens */
@media (max-width: 400px) {
  .friendrequestsuccess-content {
    width: 90%; /* Adjust width on smaller screens */
    padding: 20px;
  }

  .friendrequestsuccess-btn-close {
    font-size: 1rem;
    padding: 12px 20px;
  }
}
