/* Import Google Font: Poppins */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600;700&display=swap');

/* Universal Global Setup */
* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

html, body {
    height: 100%;
    overflow-x: hidden; /* Prevent horizontal scrolling */
}

/* Dashboard container */
.dashboard {
    font-family: 'Poppins', sans-serif;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100vh;
    position: relative;
    padding-top: 80px; /* Space for fixed header */
}

/* Modal Overlay */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Ensure modal is above other content */
}

/* Modal Content */
.modal-content {
    background-color: #fff;
    padding: 30px;
    border-radius: 8px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
    max-width: 600px;
    width: 90%;
    text-align: center;
}

.modal-content h2 {
    font-size: 1.8rem;
    color: #6A2C8B; /* Deep Purple */
    margin-bottom: 15px;
}

.modal-content p {
    font-size: 1rem;
    color: #4E4B56; /* Dark Slate */
    margin-bottom: 20px;
}

.modal-links a {
    color: #6A2C8B;
    text-decoration: none;
    font-weight: 600;
    display: block;
    margin: 5px 0;
}

.modal-links a:hover {
    color: #9B6BBF; /* Lavender Purple */
}

.modal-buttons {
    margin-top: 20px;
}

.modal-buttons button {
    padding: 10px 25px;
    font-size: 1.1rem;
    border: none;
    border-radius: 25px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
    margin: 0 10px;
}

.accept-button {
    background-color: #6A2C8B; /* Deep Purple */
    color: white;
}

.accept-button:hover {
    background-color: #9B6BBF; /* Lavender Purple */
    transform: translateY(-3px);
}

.decline-button {
    background-color: #FF7FB6; /* Soft Pink */
    color: white;
}

.decline-button:hover {
    background-color: #FF5370; /* Slightly darker pink */
    transform: translateY(-3px);
}
