@import url('https://fonts.googleapis.com/css2?family=Dancing+Script&family=Lora&family=Pinyon+Script&family=Great+Vibes&display=swap');

/* Ensure html and body take up full height */
html, body {
    height: 100%;
    margin: 0;
    padding: 0;
    overflow: hidden;  /* Prevent scrolling */
    box-sizing: border-box;
}

/* Container for the homepage */
.homepage {
    font-family: 'Dancing Script', cursive;
    position: relative;
    width: 100%;
    height: 100vh;  /* Full viewport height */
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    background-image: url('../assets/loginbg.jpeg');
    background-repeat: no-repeat;
    background-size: cover;
}

/* Styling for each slider */
.slider {
    position: relative;
    width: 100%;
    height: 100vh;  /* Full viewport height */
    background-size: cover;  /* Ensures the background image covers the entire container */
    background-position: center;  /* Centers the background image */
    display: flex;
    justify-content: center;
    align-items: center;
}

/* Content inside the slider */
.slider-content {
    position: absolute;
    z-index: 1;  /* Ensures content is above the background image */
    text-align: center;
    color: #fff;  /* White color for text */
    padding: 20px;
    background: rgba(0, 0, 0, 0.5);  /* Semi-transparent black background for readability */
    border-radius: 10px;
    max-width: 800px;  /* Controls the width of the content */
    width: 100%;
}

/* Heading and paragraph styling */
.slider h2 {
    font-size: 2.5rem;
    margin-bottom: 10px;
}

.slider p {
    font-size: 1.2rem;
    margin-bottom: 20px;
}

/* Next button at the top-right corner */
.next-btn {
    position: absolute;
    top: 20px;
    right: 20px;
    background-color: #6A2C8B;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.next-btn:hover {
    background-color: #FF7FB6;  /* Soft pink hover effect */
}

/* Optional: Styling for the login form */
.login {
    text-align: center;
    margin-top: -190px;
}

.login h2 {
    font-size: 2rem;
    margin-bottom: 20px;
}

.login form {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.login form input {
    padding: 10px;
    margin: 10px 0;
    border-radius: 5px;
    border: 1px solid #ddd;
    width: 80%;
    max-width: 300px;
}

.login form button {
    background-color: #6A2C8B;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

/* Modal Overlay */
.privacyterm-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  /* Modal Container */
  .privacyterm-modal {
    background-color: #fff;
    border-radius: 8px;
    width: 90%;
    max-width: 400px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.25);
    padding: 20px;
    text-align: center;
    animation: fadeIn 0.3s ease-in-out;
  }
  
  /* Modal Header */
  .privacyterm-modal h2 {
    margin: 0;
    font-size: 1.5rem;
    color: #333;
    margin-bottom: 15px;
  }
  
  /* Modal Paragraph */
  .privacyterm-modal p {
    font-size: 1rem;
    color: #555;
    line-height: 1.5;
    margin-bottom: 20px;
  }
  
  /* Links in Paragraph */
  .privacyterm-modal p a {
    color: #007BFF;
    text-decoration: none;
  }
  
  .privacyterm-modal p a:hover {
    text-decoration: underline;
  }
  
  /* Modal Buttons */
  .privacyterm-modal-buttons {
    display: flex;
    justify-content: space-between;
    gap: 10px;
  }
  
  /* Buttons */
  .privacyterm-modal-buttons button {
    flex: 1;
    padding: 10px 15px;
    border: none;
    border-radius: 4px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.2s;
  }
  
  .privacyterm-modal-buttons button:first-child {
    background-color: #28a745;
    color: #fff;
  }
  
  .privacyterm-modal-buttons button:first-child:hover {
    background-color: #218838;
  }
  
  .privacyterm-modal-buttons button:last-child {
    background-color: #dc3545;
    color: #fff;
  }
  
  .privacyterm-modal-buttons button:last-child:hover {
    background-color: #c82333;
  }
  
  /* Fade-in Animation */
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: scale(0.9);
    }
    to {
      opacity: 1;
      transform: scale(1);
    }
  }
  