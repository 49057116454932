/* Full-Page Container Styles */
.privacy-container {
    width: 100%;
    height: 100vh; /* Full viewport height */
    margin: 0;
    padding: 20px;
    background-color: #f9f9f9;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    font-family: 'Arial', sans-serif;
    color: #333;
    line-height: 1.6;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    overflow-y: auto; /* Enable vertical scrolling if content exceeds viewport height */
    overflow-x: hidden; /* Disable horizontal scrolling */
    padding-bottom: 20px; /* To prevent cutting off the last part of the content */
  }
  
  /* Header Styles */
  .privacy-header {
    font-size: 2rem;
    font-weight: bold;
    text-align: center;
    margin-bottom: 20px;
    color: #444;
  }
  
  .privacy-effective-date {
    font-size: 0.9rem;
    text-align: center;
    color: #777;
    margin-bottom: 20px;
  }
  
  /* Section Styles */
  .privacy-section {
    margin-bottom: 20px;
  }
  
  .privacy-subheader {
    font-size: 1.5rem;
    color: #555;
    margin-bottom: 10px;
    border-bottom: 2px solid #ddd;
    padding-bottom: 5px;
  }
  
  .privacy-text {
    font-size: 1rem;
    color: #555;
    margin-bottom: 10px;
  }
  
  /* List Styles */
  .privacy-list {
    list-style-type: disc;
    margin-left: 20px;
    padding-left: 10px;
  }
  
  .privacy-list-item {
    margin-bottom: 5px;
    font-size: 1rem;
    color: #555;
  }
  
  /* Link Styles (if applicable) */
  .privacy-text a {
    color: #007bff;
    text-decoration: none;
  }
  
  .privacy-text a:hover {
    text-decoration: underline;
  }
  
  /* Scrollbar Customization */
  .privacy-container::-webkit-scrollbar {
    width: 10px;
  }
  
  .privacy-container::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 8px;
  }
  
  .privacy-container::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 8px;
  }
  
  .privacy-container::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .privacy-container {
      padding: 15px;
    }
  
    .privacy-header {
      font-size: 1.8rem;
    }
  
    .privacy-subheader {
      font-size: 1.3rem;
    }
  
    .privacy-text,
    .privacy-list-item {
      font-size: 0.95rem;
    }
  }
  