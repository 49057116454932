/* Premium Account Section */
.opt-premium-status {
  padding: 15px;
  background-color: #6A2C8B; /* Deep Purple */
  color: #FFFFFF;
  border-radius: 8px;
  margin-bottom: 20px;
}

.opt-premium-status h3 {
  font-size: 4vw; /* Use a relative font size */
  color: #FFF;
}

.opt-premium-status p {
  font-size: 2vw;
  color: #FAD0D0; /* Light Blush Pink */
}

/* Apply Poppins font */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap');

.opt-profile-page {
  font-family: 'Poppins', sans-serif;
  background-color: #DCDCDC;
  color: #000000;
  height: 100vh; /* Use full screen height */
  overflow: hidden; /* Prevent horizontal scrolling */
}

.opt-profile-content {
  width: 100%;
  max-width: 100%; /* Ensure content uses full width */
  margin: 0 auto;
  background-color: #FFFFFF;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  height: calc(100vh - 40px); /* Take full height minus padding */
  overflow-y: auto; /* Enable vertical scrolling when content exceeds screen height */
}

/* Scrollbar styling */
.opt-profile-content::-webkit-scrollbar {
  width: 1px; /* Smaller scrollbar width */
}

.opt-profile-content::-webkit-scrollbar-track {
  background: #f1f1f1; /* Light background for the track */
}

.opt-profile-content::-webkit-scrollbar-thumb {
  background: #888; /* Darker color for the thumb */
  border-radius: 10px; /* Rounded thumb */
}

.opt-profile-content::-webkit-scrollbar-thumb:hover {
  background: #555; /* Darker color when hovered */
}

.opt-profile-options {
  margin-top: 20px;
}

.opt-profile-options h3 {
  font-size: 4vw; /* Responsive font size based on viewport width */
  color: #4E1D6D;
  margin-top: 20px;
}

.opt-profile-options ul {
  list-style: none;
  padding: 0;
}

.opt-profile-options ul li {
  padding: 12px 20px;
  cursor: pointer;
  font-size: 3vw; /* Responsive font size based on viewport width */
  border-bottom: 1px solid #DCDCDC;
}

.opt-profile-options ul li:hover {
  background-color: #7D4F99;
  color: #ffffff;
}

.opt-profile-options ul li:last-child {
  border-bottom: none;
}

button {
  padding: 10px 20px;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  font-family: 'Poppins', sans-serif;
}

button.opt-logout-button {
  background-color: #E14D92;
  color: rgb(0, 0, 0);
  font-size: 3vw; /* Responsive font size */
  margin-top: 20px;
}

button.opt-delete-button {
  background-color: #9B6BBF;
  color: white;
  font-size: 3vw; /* Responsive font size */
  margin-top: 10px;
}

/* Media Queries for smaller screen sizes */
@media (max-width: 1024px) {
  .opt-profile-options h3 {
    font-size: 4.5vw; /* Adjust font size for medium screens */
  }

  .opt-profile-options ul li {
    font-size: 2.5vw; /* Adjust font size for medium screens */
  }

  button.opt-logout-button, button.opt-delete-button {
    font-size: 2.5vw; /* Adjust font size for medium screens */
  }
}

@media (max-width: 768px) {
  .opt-profile-options h3 {
    font-size: 5vw; /* Larger font size for smaller screens */
  }

  .opt-profile-options ul li {
    font-size: 3vw; /* Larger font size for smaller screens */
  }

  button.opt-logout-button, button.opt-delete-button {
    font-size: 3vw; /* Larger font size for smaller screens */
  }
}

@media (max-width: 480px) {
  .opt-profile-options h3 {
    font-size: 6vw; /* Even larger font size for very small screens */
  }

  .opt-profile-options ul li {
    font-size: 4vw; /* Even larger font size for very small screens */
  }

  button.opt-logout-button, button.opt-delete-button {
    font-size: 4vw; /* Even larger font size for very small screens */
  }
}
