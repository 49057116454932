@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap');

.friends-page {
    padding: 20px;
    font-family: 'Arial', sans-serif;
    color: white;
  }
  
  .friends-list {
    display: flex;
    flex-direction: column;
    gap: 15px;
    max-height: 600px; /* Adjust this height to fit your layout */
    overflow-y: auto; /* Enable vertical scrolling */
    padding-right: 10px; /* Add padding to the right side for the scrollbar */
  }
  
  .friend-banner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #181818e3;
    border-radius: 8px;
    padding: 15px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.3s ease-in-out;
    cursor: pointer; /* Makes the whole banner clickable */
    width: 100%;
  }
  
  .friend-banner:hover {
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }
  
  .friend-banner-content {
    display: flex;
    align-items: center;

  }
  
  .profile-photo {
    width: 70px;
    height: 70px;
    border-radius: 50%;
    object-fit: cover;
  }
  
  .friend-info {
    display: flex;
    flex-direction: column;
  }
  
  .friend-info h3 {
    margin: 0;
    font-size: 18px;
    font-weight: bold;
  }
  
  .friend-info p {
    margin: 0;
    color: #ffffff;
    font-size: 14px;
  }
  
  /* Golden Sparkling Premium Badge */
  .friend-badge {
    background-color: #ffd700; /* Gold color */
    color: white;
    padding: 10px 10px;
    border-radius: 20px;
    font-size: 12px;
    position: relative;
    animation: sparkle 1.5s linear infinite;
    left: 20px; /* Shift the badge slightly to the right */
  }
  
  
  .friend-badge .badge-text {
    position: relative;
  }
  
  .friend-badge:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    height: 2px;
    background: linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.8), transparent);
    animation: shine 1.5s infinite;
  }
  
  @keyframes sparkle {
    0% {
      text-shadow: 0 0 5px rgba(255, 215, 0, 0.8), 0 0 10px rgba(255, 215, 0, 0.6), 0 0 15px rgba(255, 215, 0, 0.4);
    }
    50% {
      text-shadow: 0 0 10px rgba(255, 215, 0, 1), 0 0 20px rgba(255, 215, 0, 0.8), 0 0 30px rgba(255, 215, 0, 0.6);
    }
    100% {
      text-shadow: 0 0 5px rgba(255, 215, 0, 0.8), 0 0 10px rgba(255, 215, 0, 0.6), 0 0 15px rgba(255, 215, 0, 0.4);
    }
  }
  
  @keyframes shine {
    0% {
      transform: translateX(-100%);
    }
    50% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(-100%);
    }
  }
  
  .friend-buttons {
    display: flex;
    gap: 15px; /* Increased gap between buttons */
  }
  
  .message-button { /* Style for the static Message button */
    background-color: #f1c40f; /* Yellow color */
    color: white;
    padding: 10px 20px;
    font-size: 14px;
    font-weight: bold;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .message-button:hover {
    background-color: #f39c12; /* Darker yellow */
  }

  .friend-badge {
    background-color: #ffd700; /* Gold color */
    color: white;
    padding: 8px 15px;
    border-radius: 20px;
    font-size: 14px;
    font-weight: bold;
    margin-left: auto;
    position: relative;
    display: inline-block;
    animation: sparkle 1.5s linear infinite; /* Added sparkle animation */
  }
  
  .friend-badge .badge-text {
    position: relative;
  }
  
  .friend-badge:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    height: 2px;
    background: linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.8), transparent);
    animation: shine 1.5s infinite;
  }
  
  @keyframes sparkle {
    0% {
      text-shadow: 0 0 5px rgba(255, 215, 0, 0.8), 0 0 10px rgba(255, 215, 0, 0.6), 0 0 15px rgba(255, 215, 0, 0.4);
    }
    50% {
      text-shadow: 0 0 10px rgba(255, 215, 0, 1), 0 0 20px rgba(255, 215, 0, 0.8), 0 0 30px rgba(255, 215, 0, 0.6);
    }
    100% {
      text-shadow: 0 0 5px rgba(255, 215, 0, 0.8), 0 0 10px rgba(255, 215, 0, 0.6), 0 0 15px rgba(255, 215, 0, 0.4);
    }
  }
  
  @keyframes shine {
    0% {
      transform: translateX(-100%);
    }
    50% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(-100%);
    }
  }
  