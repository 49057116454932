/* termsandconditions.style.css */

/* General styles */
body {
    font-family: Arial, sans-serif;
    background-color: #f9f9f9;
    margin: 0;
    padding: 0;
  }
  
  .terms-container {
    max-width: 900px;
    margin: 0 auto;
    padding: 20px;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    overflow-y: auto;
    height: 100vh; /* This makes the container scrollable */
  }
  
  .terms-header {
    text-align: center;
    font-size: 2rem;
    margin-bottom: 10px;
    color: #333;
  }
  
  .terms-effective-date {
    text-align: center;
    font-size: 0.9rem;
    color: #777;
    margin-bottom: 20px;
  }
  
  .terms-section {
    margin-bottom: 30px;
  }
  
  .terms-subheader {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 10px;
    color: #2c3e50;
  }
  
  .terms-text {
    font-size: 1rem;
    line-height: 1.6;
    color: #555;
    margin-bottom: 10px;
    text-align: justify;
  }
  
  /* Scrollable container */
  .terms-container::-webkit-scrollbar {
    width: 8px;
  }
  
  .terms-container::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 4px;
  }
  
  .terms-container::-webkit-scrollbar-thumb:hover {
    background-color: #555;
  }
  
  .terms-container::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 4px;
  }
  
  /* Responsive design */
  @media (max-width: 768px) {
    .terms-container {
      padding: 15px;
    }
  
    .terms-header {
      font-size: 1.5rem;
    }
  
    .terms-subheader {
      font-size: 1.25rem;
    }
  
    .terms-text {
      font-size: 0.9rem;
    }
  }
  